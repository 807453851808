import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toDisplayFormatWithTime } from '../../lib/utils/formatDate';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { Button, Col, Divider, Modal, Row, Table } from 'antd';
import classNames from 'classnames';
import {
  getReadAllNotifications,
  getUnreadNotifications,
  markAsReadNotification,
} from '../../store/slices/notificationsSlice/notificationsSlice';
import './Notifications.less';
import { NotificationTypes } from '../../lib/const/notificationTypes';
import { ReactComponent as BackArrow } from '../../lib/img/back-arrow.svg';
import NotificationsTabs from '../NotificationsTabs/NotificationsTabs';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

interface Props {
  page: number;
  setPage: (number) => void;
  fetchNotifications: () => void;
}

function Notifications(props: Props) {
  const { page, setPage } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { meUserFull } = useAppSelector((state) => state.persons);

  const columns = [
    {
      dataIndex: 'createDate',
      key: 'createDate',
      width: '13%',
      render: (item) => toDisplayFormatWithTime(item),
    },
    {
      dataIndex: 'title',
      width: '13%',
    },
    {
      dataIndex: 'body',
      width: '43%',
    },
  ];
  const { allNotifications, allNotificationsLoading, totalNotifications } = useAppSelector(
    (state) => state.notifications
  );
  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    if (checkIsLoaded(allNotificationsLoading)) {
      setTableData(
        allNotifications.map((item, index) => {
          return {
            key: item.id,
            id: item.id,
            createDate: item.createDate,
            // @ts-ignore
            title: item.payload.title,
            // @ts-ignore
            body: item.payload.body,
            // @ts-ignore
            data: item.payload.data,
            // @ts-ignore
            readDate: item.readDate,
          };
        })
      );
    }
  }, [allNotificationsLoading, allNotifications]);

  const onClickReadAll = () => {
    Modal.confirm({
      title: 'Вы действительно хотите отметить прочитанными все уведомления?',
      onOk: () => {
        if (meUserFull?.userId) {
          dispatch(getReadAllNotifications()).then(() => {
            props.fetchNotifications();
            if (meUserFull.userId) {
              dispatch(getUnreadNotifications({ page: 0, size: 100, userId: meUserFull.userId, searchUnread: true }));
            }
          });
        }
      },
    });
  };

  return (
    <div className="notifications pt10">
      <Row className="ml20 mb12 pr20" wrap={false}>
        <Col style={{ width: '100%' }}>
          <Row wrap={false}>
            <Col style={{ maxWidth: 450, width: '100%' }}>
              <h2 className="title mb0">Уведомления</h2>
              <Divider className="thin-divider" />
            </Col>
            <Col>
              <Button
                style={{ backgroundColor: '#F4F4F4', width: 150 }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Назад
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Button
                style={{ backgroundColor: '#7590A3', width: 240, color: '#fff', fontSize: 15 }}
                onClick={onClickReadAll}
              >
                Отметить прочитанными все
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="pl20 pr20">
        <NotificationsTabs />
      </div>

      <Divider className="thin-divider" />

      <Table
        dataSource={tableData}
        columns={columns}
        showHeader={false}
        loading={checkIsLoading(allNotificationsLoading)}
        pagination={{
          pageSize: 20,
          current: page,
          onChange: (page) => setPage(page),
          position: ['bottomCenter'],
          total: totalNotifications,
        }}
        scroll={{ y: '75vh' }}
        rowClassName={(record, index) => {
          return classNames(
            {
              'even-number': index % 2 !== 0,
              'odd-number': index % 2 === 0,
            },
            { read: Boolean(record.readDate) },
            { unread: Boolean(!record.readDate) }
          );
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record?.data?.link === NotificationTypes.Информация) {
              window.open(`/info`);
              dispatch(markAsReadNotification({ id: record.id }));
            } else if (record?.data?.link === NotificationTypes.Список_Просьб) {
              window.open(`/applications/list`);
              dispatch(markAsReadNotification({ id: record.id }));
            } else {
              window.open(`/applications/list/${record.data.applicationId}/main`);
              dispatch(markAsReadNotification({ id: record.id }));
            }
          },
        })}
      />
    </div>
  );
}

export default Notifications;
