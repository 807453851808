import React, { FC, useState } from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { Form, Row, Col, Button, Modal, Divider, Input, Checkbox, Table, message } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as BinIcon } from '../../lib/img/bin.svg';
import { ReactComponent as PencilIcon } from '../../lib/img/pencil.svg';
import { ReactComponent as PlusIcon } from '../../lib/img/plus.svg';
import { ToggleEditPhoneModal, EditPhoneModalOptions } from '../../lib/interfaces/EditPhoneModalMode';
import './PhonesTable.less';
import usePhoneMask from '../../lib/utils/usePhoneMask';
import { useAppSelector } from '../../store/hooks';

interface IProps {
  isDisabled?: boolean;
  onChange?: () => void;
  style?: { [k: string]: any };
  required?: boolean;
  isValidMainPhone?: boolean;
}

export const PhonesTable: FC<IProps> = ({ isDisabled, onChange, style, required = true, isValidMainPhone = true }) => {
  const [addPhoneForm] = Form.useForm();
  const [deletedPhoneIndex, setDeletedPhoneIndex] = useState<null | number>(null);
  const [isDefChecked, setIsDefChecked] = useState(false);

  const { phoneMask, getMaskedPhoneNumber, getUnmaskedPhoneNumber, phoneInputPlaceholder, phoneValidator } =
    usePhoneMask();

  const [editPhoneModalOptions, setEditPhoneModalOptions] = useState<EditPhoneModalOptions>({
    mode: '',
  });

  const toggleEditPhoneModal: ToggleEditPhoneModal = (options) => {
    setEditPhoneModalOptions(options);
  };

  const { phoneType } = useAppSelector((state) => state.dictionaries);

  const handleCloseModal = () => {
    addPhoneForm.resetFields();
    toggleEditPhoneModal({ mode: '' });
    setIsDefChecked(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Телефон',
      key: 'phone',
      dataIndex: 'phone',
      width: '23%',
      render: (phoneNumber) => getMaskedPhoneNumber(phoneNumber),
    },
    {
      title: 'Тип',
      key: 'phoneTypeId',
      dataIndex: 'phoneTypeId',
      width: '23%',
      render: (phoneTypeId) => phoneType.find(({ id }) => id === phoneTypeId)?.name,
    },
    {
      title: 'Комментарий',
      key: 'dsc',
      dataIndex: 'dsc',
      render: (dsc) => <div style={{ whiteSpace: 'pre-wrap' }}>{dsc}</div>,
    },
    {
      key: 'actions',
      width: '80px',
      render: (v, record, index) => {
        const handleEdit = () => {
          toggleEditPhoneModal({ mode: 'edit', index });
          addPhoneForm.setFieldsValue({
            phone: record.phone,
            isMain: record.phoneTypeId === 1,
            dsc: record.dsc,
          });
        };
        return (
          <>
            <Button
              icon={<PencilIcon />}
              className="button-icon mt3"
              type="text"
              disabled={isDisabled}
              onClick={handleEdit}
            />
            <Button
              icon={<BinIcon />}
              className="button-icon ml8 mr8"
              type="text"
              disabled={isDisabled}
              onClick={() => {
                setDeletedPhoneIndex(index);
              }}
            />
          </>
        );
      },
    },
  ];

  const renderEditPhoneModal = (phones, setFieldsValue) => {
    /* Если создаваемый/редактируемый номер имеет тип "Основной" (phoneTypeId: 1), 
    тип остальных телефонов заменяется на "Дополнительный" (phoneTypeId: 2)*/
    const handleAddPhone = (values) => {
      const phoneTypeId = values.isMain ? 1 : 2;
      const addedPhone = {
        phone: values.phone,
        phoneTypeId,
        dsc: values.dsc,
      };
      const updatedPhones = phones.map((phone) => (values.isMain ? { ...phone, phoneTypeId: 2 } : phone));
      setFieldsValue({ phones: [addedPhone, ...updatedPhones] });
      onChange && onChange();
      handleCloseModal();
    };

    const handleEditPhone = (values) => {
      const updatedPhones = phones.map((phone, index) => {
        if (index === editPhoneModalOptions.index) {
          return {
            ...phone,
            phone: values.phone,
            phoneTypeId: values.isMain ? 1 : 2,
            dsc: values.dsc,
          };
        }
        return values.isMain ? { ...phone, phoneTypeId: 2 } : phone;
      });
      setFieldsValue({ phones: updatedPhones });
      onChange && onChange();
      handleCloseModal();
    };

    const actions = {
      add: handleAddPhone,
      edit: handleEditPhone,
    };

    const defaultIsMain = phones?.length === 0;
    if (defaultIsMain && !isDefChecked) {
      addPhoneForm.setFieldsValue({ isMain: defaultIsMain });
      setIsDefChecked(true);
    }

    return (
      <Modal
        open={Boolean(editPhoneModalOptions.mode)}
        width="690px"
        footer={null}
        onCancel={handleCloseModal}
        destroyOnClose
      >
        <h2>{`${editPhoneModalOptions.mode === 'edit' ? 'Редактировать' : 'Добавить'} номер`}</h2>
        <Divider className="thin-divider mt10" />
        <Form form={addPhoneForm} layout="vertical" onFinish={actions[editPhoneModalOptions.mode]}>
          <Row>
            <Form.Item
              name="phone"
              label="Tелефон"
              rules={[
                {
                  required,
                  validator: phoneValidator,
                  message: '',
                },
              ]}
              getValueFromEvent={({ maskedValue }) => getUnmaskedPhoneNumber(maskedValue)}
            >
              <MaskedInput
                mask={[{ mask: phoneMask }]}
                name="phone"
                //TODO Eugene. Разобраться: без указания prefix=" " не срабатывает подсветка валидации
                prefix=" "
                placeholder={phoneInputPlaceholder}
              />
            </Form.Item>
            <Form.Item name="isMain" valuePropName="checked" label=" " className="ml20">
              <Checkbox>
                <span style={{ fontSize: '16px' }}>Сделать основным</span>
              </Checkbox>
            </Form.Item>
          </Row>
          <Form.Item
            style={{ whiteSpace: 'pre-wrap' }}
            name="dsc"
            label="Комментарий"
            className="phones-table__dscTextArea"
          >
            <Input.TextArea maxLength={255} />
          </Form.Item>
          <Row gutter={20} className="mt20 mb10">
            <Col className="ml-auto">
              <Button onClick={handleCloseModal}>Отмена</Button>
            </Col>
            <Col className="mr-auto">
              <Button type="primary" onClick={addPhoneForm.submit}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };

  const renderDeletePhoneModal = (phones, setFieldsValue) => {
    const handleCloseModal = () => {
      setDeletedPhoneIndex(null);
    };
    const handleDelete = () => {
      setFieldsValue({
        phones: phones.filter((phone, index) => index !== deletedPhoneIndex),
      });
      onChange && onChange();
      handleCloseModal();
    };
    const dataSource = deletedPhoneIndex === null ? [] : [phones[deletedPhoneIndex]];

    return (
      <Modal visible={deletedPhoneIndex !== null} width="690px" footer={null} onCancel={handleCloseModal}>
        <h2>Удаление телефонного номера</h2>
        <Divider className="thin-divider mt10" />
        <Table
          size="small"
          columns={columns.filter(({ key }) => key !== 'actions')}
          dataSource={dataSource}
          className="table__no-hover"
          pagination={false}
        />
        <Row gutter={20} className="mt20 mb10">
          <Col className="ml-auto">
            <Button onClick={handleCloseModal}>Отмена</Button>
          </Col>
          <Col className="mr-auto">
            <Button type="primary" onClick={handleDelete}>
              Удалить
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  const validatePhones = (rec, phones) => (isEmpty(phones) ? Promise.reject() : Promise.resolve());

  const validateMainPhone = (rec, phones) =>
    !isValidMainPhone || phones.some((item) => item.phoneTypeId === 1)
      ? Promise.resolve()
      : Promise.reject().then(
          message.error(
            'В анкете не указан основной номер телефона. Для сохранения изменений выберите один номер телефона в качестве основного'
          )
        );

  return (
    <>
      <Form.Item shouldUpdate className="phones-table form-item-dependencies">
        {(createApplicationForm) => {
          const errors = createApplicationForm.getFieldError('phones');
          const formStyle = errors.length > 0 ? { outline: '1px solid #ff4d4f' } : {};
          const phones =
            createApplicationForm.getFieldValue('phones')?.map((phone, index) => ({ ...phone, key: index })) || [];

          return (
            <>
              <Form.Item
                name="phones"
                rules={[
                  { validator: validatePhones },
                  {
                    validator: validateMainPhone,
                  },
                ]}
                style={formStyle}
              >
                <Table
                  size="small"
                  className="table__no-hover striped-table"
                  columns={columns}
                  dataSource={phones}
                  pagination={false}
                  scroll={{ y: '120px' }}
                  rowClassName={(r, i) => (i % 2 ? '' : 'striped-table__oddRow')}
                  style={style}
                />
              </Form.Item>
              {renderEditPhoneModal(phones, createApplicationForm.setFieldsValue)}
              {renderDeletePhoneModal(phones, createApplicationForm.setFieldsValue)}
            </>
          );
        }}
      </Form.Item>
      <Button
        type="primary"
        className="phones-table__addPhoneBtn"
        disabled={isDisabled}
        icon={<PlusIcon />}
        onClick={() => toggleEditPhoneModal({ mode: 'add' })}
      >
        Добавить телефон
      </Button>
    </>
  );
};
