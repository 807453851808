import React, { useState } from 'react';
import { Button, Col, Divider, message, Modal, Row, Tooltip, Upload } from 'antd';
import uniqueId from 'lodash/uniqueId';
import { ReactComponent as DeleteFileIcon } from '../../lib/img/delete-file-icon.svg';
import { ReactComponent as FileDoc } from '../../lib/img/formats/doc.svg';
import { ReactComponent as FileDocx } from '../../lib/img/formats/docx.svg';
import { ReactComponent as FileJpeg } from '../../lib/img/formats/jpeg.svg';
import { ReactComponent as FileJpg } from '../../lib/img/formats/jpg.svg';
import { ReactComponent as FilePdf } from '../../lib/img/formats/pdf.svg';
import { ReactComponent as FilePng } from '../../lib/img/formats/png.svg';
import { ReactComponent as FileXls } from '../../lib/img/formats/xls.svg';
import { ReactComponent as FileXlsx } from '../../lib/img/formats/xlsx.svg';

import axios from 'axios';
import { ReactComponent as UploadFileIcon } from '../../lib/img/add-file.svg';
import usePermissions from '../../lib/utils/usePermissions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { PERSON_FILES_DELETE, PERSON_FILES_DOWNLOAD, PERSON_FILES_UPLOAD } from '../../lib/const/permissions';
import { useParams } from 'react-router-dom';
import { deletePersonFileRoute, downloadPersonFileRoute, uploadPersonFileRoute } from '../../lib/routes/persons';
import { getPersonFiles } from '../../store/slices/personsSlice/personsSlice';
import { DownloadApplicationFile } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { saveAs } from 'file-saver';
import { RcFile } from 'antd/lib/upload';

const beforeUpload = (file: RcFile) => {
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Размер файла не должен превышать 5Мб.');
  }
  return isLt2M;
};

function FilesList(props) {
  const [hasAccess] = usePermissions();
  const { fileId } = useParams();
  const { files } = useAppSelector((state) => state.persons);
  const [deletedFileName, setDeletedFileName] = useState<string>('');
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const fileComponent = {
    doc: FileDoc,
    docx: FileDocx,
    jpeg: FileJpeg,
    jpg: FileJpg,
    pdf: FilePdf,
    png: FilePng,
    xls: FileXls,
    xlsx: FileXlsx,
};

  const downloadPersonFile: DownloadApplicationFile = async ({ fileName }) => {
    if (fileId) {
      const url = downloadPersonFileRoute({ personId: fileId, fileName });
      const { data } = await axios.get(url, {
        responseType: 'blob',
      });
      saveAs(data, fileName);
    }
  };

  const renderAttachments = () => {
    const filesList = files.map((file) => {
      const fileName = file.name.replace('/files/', '');
      const fileFormat = file.name.split('.').pop()

      const SpecificFile = fileFormat && fileComponent[fileFormat];

      
      return (
        <Col key={uniqueId()}>
          {hasAccess([PERSON_FILES_DELETE]) && (
            <Tooltip title="Удалить файл">
              <DeleteFileIcon
                style={{ position: 'relative', top: '-28px', left: '40px' }}
                className="cursor-pointer"
                onClick={() => {
                  setDeletedFileName(file.name);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Скачать файл">
            <SpecificFile
              className="cursor-pointer"
              title={fileName}
              onClick={() => {
                if (hasAccess([PERSON_FILES_DOWNLOAD])) {
                  downloadPersonFile({ fileName });
                } else {
                  message.error('Скачивание файлов недоступно', 3);
                }
              }}
            />
          </Tooltip>
        </Col>
      );
    });

    const acceptedTypes =
      '.jpg, .jpeg, .png, .pdf, .rtf, .doc, .docx, msword, vnd.openxmlformats-officedocument.wordprocessingml.document, .xls, .xlsx, vnd.openxmlformats-officedocument.spreadsheetml.sheet, vnd.ms-excel';

    const uploadFiles = ({ file }) => {
      const fileType = file.type.split('/')[1];
      if (acceptedTypes.includes(fileType)) {
        if (file.originFileObj !== undefined) {
          const formData = new FormData();
          formData.append('files', file.originFileObj);
          setLoadingData(true);
          axios
            .post(uploadPersonFileRoute({ personId: String(fileId) }), formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(() => {
              message.success('Файл добавлен', 3);
              if (fileId) {
                dispatch(getPersonFiles({ personId: fileId }));
              }
            })
            .catch(() => {
              message.error(
                <>
                  <div>При загрузке файла возникла ошибка.</div>
                  <div>Пожалуйста, повторите попытку позднее</div>
                </>,
                3
              );
            })
            .finally(() => {
              setLoadingData(false);
            });
        }
      } else {
        message.error(
          <>
            <div>Данный формат файлов не поддерживается.</div>
            <div>Пожалуйста, загрузите файл pdf, doc, docx, png, jpg, jpeg, xls, xlsx</div>
          </>,
          3
        );
      }
    };

    return (
      <div className="mt10 mb10" style={{ backgroundColor: 'white', width: '100%', padding: 10 }}>
        <h3 className="text-bold mb3">Вложения</h3>
        <Divider className="mt0 mb7" />
        <Row className="pl10 mt20 pb20" gutter={20}>
          {filesList}
          {hasAccess([PERSON_FILES_UPLOAD]) && (
            <Upload
              beforeUpload={beforeUpload}
              accept={acceptedTypes}
              multiple
              showUploadList={false}
              customRequest={() => {}}
              onChange={uploadFiles}
            >
              <UploadFileIcon
                style={{
                  cursor: 'pointer',
                  marginLeft: '15px',
                }}
              />
            </Upload>
          )}
        </Row>
      </div>
    );
  };

  const renderDeleteFileModal = () => {
    const handleCloseModal = () => {
      setDeletedFileName('');
    };
    const handleDelete = () => {
      setLoadingData(true);
      axios
        .delete(
          deletePersonFileRoute({
            personId: String(fileId),
            fileName: deletedFileName,
          })
        )
        .then(() => {
          message.success('Файл удален');
          if (fileId) {
            dispatch(getPersonFiles({ personId: fileId }));
          }
        })
        .catch(() => {
          message.error(
            <>
              <div>При удалении файла возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        })
        .finally(() => {
          handleCloseModal();
          setLoadingData(false);
        });
    };
    return (
      <Modal open={Boolean(deletedFileName)} width="690px" footer={null} onCancel={handleCloseModal}>
        <h2>Удаление файла</h2>
        <Divider className="thin-divider mt10" />
        <div>Вы действительно хотите удалить выбранный файл: {deletedFileName}?</div>
        <Row gutter={20} className="mt20 mb10">
          <Col className="ml-auto">
            <Button onClick={handleCloseModal}>Отмена</Button>
          </Col>
          <Col className="mr-auto">
            <Button type="primary" onClick={handleDelete}>
              Удалить
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <Row>
      {renderDeleteFileModal()}
      {renderAttachments()}
    </Row>
  );
}

export default FilesList;
