import { baseUrl } from '../routes/baseUrl';
import { ours } from '../const/urls';

const featuresConfig = {
  roleSystem: (() => {
    switch (baseUrl) {
      case ours:
        return true;
      default:
        return true;
    }
  })(),
};

export default featuresConfig;
