import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import usePermissions from '../../lib/utils/usePermissions';
import {
  PERSON_READ,
  PERSON_READ_FULLACCESS,
  PERSON_READ_HISTORY,
  PERSON_READ_MYCOMMENTS,
  PERSON_READ_PROJECTS,
} from '../../lib/const/permissions';
import './NotificationsTabs.less';

function NotificationsTabs({}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasAccess] = usePermissions();

  const menuFields = [
    {
      name: 'Все',
      route: 'all',
      size: 49,
      hasAccess: hasAccess([PERSON_READ_FULLACCESS, PERSON_READ]) || true,
    },
    {
      name: 'Новости',
      route: 'news',
      size: 108,
      hasAccess: false,
    },
    {
      name: 'Работа с просьбами',
      route: 'work_with_app',
      size: 156,
      hasAccess: hasAccess([PERSON_READ_FULLACCESS, PERSON_READ_PROJECTS]) || true,
    },
    {
      name: 'Подходящие просьбы',
      route: 'suitable_app',
      size: 160,
      hasAccess: hasAccess([PERSON_READ_FULLACCESS, PERSON_READ_HISTORY]) || true,
    },
  ];

  return (
    <div className="notifications-navbar-menu">
      {menuFields.map(({ route, name, size, hasAccess }) => {
        if (hasAccess) {
          const width = `${size}px`;
          const path = `/notifications/${route}`;
          const onClick = () => {
            navigate(path);
          };
          return (
            <div
              key={route}
              onClick={onClick}
              className="notifications-navbar-menu-item"
              style={
                path === location.pathname
                  ? {
                      width,
                      backgroundColor: '#315373',
                      color: '#fff',
                    }
                  : { width }
              }
            >
              {name}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default NotificationsTabs;
