import React from 'react';
import { Collapse, Form, Row, Col, Input, Select, Button, Space } from 'antd';
import './FilesCollapse.less';
import { useForm } from 'antd/es/form/Form';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import MaskedInput from 'antd-mask-input';
import usePhoneMask from '../../lib/utils/usePhoneMask';
import usePermissions from '../../lib/utils/usePermissions';
import { PERSONLIST_READ_ALLREGIONS } from '../../lib/const/permissions';
import { resetFilesSearchState, setFilesPage, setFilesSearchState } from '../../store/slices/rootSlice/rootSlice';
import { PersonTypes } from '../FileCard/PersonTypes';
import { MaskInputFromAntd } from '../common/MaskInputFromAntd';

const { Item } = Form;

function FilesCollapse() {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [hasAccess] = usePermissions();
  const { subwayStation, personStatuses, region, maritalStatus } = useAppSelector((state) => state.dictionaries);
  const { projects } = useAppSelector((state) => state.projects);
  const { meUserFull } = useAppSelector((state) => state.persons);
  const { filesSearchState } = useAppSelector((state) => state.root);

  const { phoneMask, phoneInputPlaceholder, getUnmaskedPhoneNumber } = usePhoneMask();

  const onFinish = () => {
    dispatch(setFilesPage(1));

    const params = { ...form.getFieldsValue(), email: form.getFieldValue('email')?.toLowerCase() };

    dispatch(setFilesSearchState(params));
  };

  const isDisabled = () => {
    if (hasAccess([PERSONLIST_READ_ALLREGIONS])) {
      return !form.getFieldValue('regionId');
    }
    return false;
  };

  return (
    <Collapse expandIconPosition="end" className="files-collapse" defaultActiveKey={1}>
      <Collapse.Panel header="Расширенный поиск" key="1">
        <Form
          form={form}
          onFinish={onFinish}
          onReset={() => {
            form.resetFields();
            form.setFieldsValue({
              fio: undefined,
              personTypeIds: [],
              excludePersonTypeId: [],
              personStatusIds: [],
              email: undefined,
              phone: undefined,
              maritalStatusId: undefined,
              birthDate: undefined,
              deathDate: undefined,
              projectIds: [],
              address: undefined,
              subwayStationId: undefined,
              excludePersonInPrayList: undefined,
            });
            dispatch(resetFilesSearchState());
            dispatch(setFilesPage(1));
          }}
          initialValues={{
            ...filesSearchState,
            regionId: hasAccess([PERSONLIST_READ_ALLREGIONS]) ? filesSearchState?.regionId : meUserFull?.regionId,
          }}
        >
          <Row gutter={12}>
            <Col span={18}>
              <Row gutter={12}>
                <Col span={6}>
                  <Item name="regionId">
                    <Select
                      className="select"
                      placeholder="Субъект РФ"
                      allowClear
                      disabled={!hasAccess([PERSONLIST_READ_ALLREGIONS]) || region?.length === 1}
                      onClear={() => form.setFieldsValue({ projectIds: [] })}
                    >
                      {region.map((region) => {
                        return (
                          <Select.Option key={region.id} value={Number(region.id)}>
                            {region.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name="fio">
                    <Input placeholder="ФИО" allowClear />
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name="email">
                    <Input placeholder="Email" allowClear />
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name="maritalStatusId">
                    <Select className="select" placeholder="Семейное положение" allowClear>
                      {maritalStatus.map((status) => {
                        return <Select.Option key={status.id}>{status.name}</Select.Option>;
                      })}
                    </Select>
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Item
                    shouldUpdate={(prevValues, currentValues) => {
                      return prevValues.regionId !== currentValues.regionId;
                    }}
                    // preserve={false}
                  >
                    {({ getFieldValue }) => {
                      return (
                        <Item name="projectIds" noStyle>
                          <Select
                            className="select"
                            mode="multiple"
                            placeholder="Проект"
                            allowClear
                            showArrow
                            disabled={isDisabled()}
                            filterOption={(input, option) =>
                              (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                            }
                          >
                            {projects.map((proj) => {
                              if (proj.regionId === getFieldValue('regionId')) {
                                return <Select.Option key={proj.id}>{proj.title}</Select.Option>;
                              }
                              return null;
                            })}
                          </Select>
                        </Item>
                      );
                    }}
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name="address">
                    <Input placeholder="Адрес" allowClear />
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name="phone" getValueFromEvent={({ maskedValue }) => getUnmaskedPhoneNumber(maskedValue)}>
                    <MaskedInput
                      mask={[{ mask: phoneMask }]}
                      prefix=" "
                      placeholder={phoneInputPlaceholder}
                      allowClear
                    />
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                {meUserFull?.personTypeId !== PersonTypes.Подопечный && (
                  <Col span={12}>
                    <Item
                      shouldUpdate={(prevValues, currentValues) => {
                        return prevValues.regionId !== currentValues.regionId;
                      }}
                    >
                      {({ getFieldValue }) => {
                        return (
                          <Item name="projectVolunteerIds" noStyle>
                            <Select
                              className="select"
                              mode="multiple"
                              placeholder="Закрепление за проектом"
                              allowClear
                              showArrow
                              disabled={isDisabled()}
                              filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                              }
                            >
                              {projects.map((proj) => {
                                if (proj.regionId === getFieldValue('regionId')) {
                                  return <Select.Option key={proj.id}>{proj.title}</Select.Option>;
                                }
                                return null;
                              })}
                            </Select>
                          </Item>
                        );
                      }}
                    </Item>
                  </Col>
                )}
                <Col span={6}>
                  <Item name="subwayStationId">
                    <Select
                      showSearch
                      className="select"
                      placeholder="Метро"
                      allowClear
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                      }
                    >
                      {subwayStation.map((subway) => {
                        return <Select.Option key={subway.id}>{subway.name}</Select.Option>;
                      })}
                    </Select>
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name="personStatusIds">
                    <Select
                      mode="multiple"
                      className="select"
                      placeholder="Статус"
                      allowClear
                      showArrow
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                      }
                    >
                      {personStatuses.map((status) => {
                        return <Select.Option key={status.id}>{status.name}</Select.Option>;
                      })}
                    </Select>
                  </Item>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row gutter={12}>
                <Col span={12}>
                  <Item name="birthDate" label="Дата рождения: " labelCol={{ span: 24 }}>
                    <MaskInputFromAntd placeholder="ДД.ММ" allowClear />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item name="deathDate" label="Дата смерти: " labelCol={{ span: 24 }}>
                    <MaskInputFromAntd placeholder="ДД.ММ" allowClear />
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end">
            <Space>
              <Item>
                <Button htmlType="reset">Сбросить</Button>
              </Item>
              <Item>
                <Button type="primary" htmlType="submit">
                  Применить
                </Button>
              </Item>
            </Space>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
}

export default FilesCollapse;
