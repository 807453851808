import { ours, prod } from '../const/urls';

/* 
  В качестве baseUrl отдается текущий домен
  для localhost - https://api.sdn104.iflex.ru
  для start-prod (только для случаев, когда нужно это сделать на локальной машине) - https://api.miloserdieapp.ru
*/

const getBaseUrl = () => {
  const { protocol, host } = document.location;
  if (host.includes('localhost')) {
    if (process.env.REACT_APP_ENV === 'prod') return prod;
    return ours;
  }
  return `${protocol}//api.${host}`;
};

export const baseUrl = getBaseUrl();
export const securityBaseUrl = baseUrl.replace("api", "auth");
export const standBaseUrl = baseUrl.replace("api.", "");
