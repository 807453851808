import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  message,
} from 'antd';
import ProfileImage from '../ProfileImage/ProfileImage';
import { useAppSelector } from '../../store/hooks';
import './UserProfile.less';
import { replaceFalse } from '../FileCard/replaceFalse';
import { displayFormat, toDisplayFormat } from '../../lib/utils/formatDate';
import PasswordHint from '../PasswordHint/PasswordHint';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';
import { profileRoute } from '../../lib/routes/profile';
import LocalStorageService from '../../services/LocalStorageService';
import usePhoneMask from '../../lib/utils/usePhoneMask';
import MaskedInput from 'antd-mask-input';
import { ReactComponent as PencilIcon } from '../../lib/img/pencil.svg';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { FieldsSubscriptions } from '../../lib/interfaces/Subscriptions';
import { ReactComponent as ArrowDownFigIcon } from '../../lib/img/arrow-down-fig.svg';
import { uniqueId } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { Link } from 'react-router-dom';
import { fioValidator } from '../../lib/utils/fioValidator';
import { fioLengthValidator } from '../../lib/utils/fioLengthValidator';
import FireBaseService from '../../services/FireBaseService/FireBaseService';
import { datePikerMaskDefault } from '../../lib/const/masks';
import InputMask from 'react-input-mask';
import { passwordPattern } from '../../lib/const/patterns';

interface Fields {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  email: string;
  birthDate: string;
  noMiddleName: boolean;
}

interface Props {
  handleEditProfile: (fields: Fields) => void;
  handleEditSubscriptions: (fields: FieldsSubscriptions) => Promise<AxiosResponse>;
  image?: any;
  handleChangePassword: (args: { oldPassword: string; newPassword: string }) => void;
  handleNewImage: (file, route) => void;
}

function UserProfile(props: Props) {
  const { handleEditProfile, handleEditSubscriptions, image, handleChangePassword, handleNewImage } = props;
  const [passwordForm] = useForm();
  const { meUserFull, personProfileShortInfo } = useAppSelector((state) => state.persons);
  const { personStatuses, personTypes, personCategoriesLoading, personCategories, appSubject, appSubjectLoading } =
    useAppSelector((state) => state.dictionaries);
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);
  const { subscriptions, subscriptionsLoading } = useAppSelector((state) => state.subscriptions);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditSubscription, setIsEditSubscription] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isPassButtonDisabled, setIsPassButtonDisabled] = useState(true);
  const { getMaskedPhoneNumber, phoneMask, phoneInputPlaceholder } = usePhoneMask();
  const [infoForm] = useForm();
  const [subscriptionForm] = useForm();
  const { hasAccess } = useMainContext();

  const [isUpdateSubscription, setIsUpdateSubscription] = useState(false);

  const onFinish = (fields) => {
    handleEditProfile(fields);
  };

  const primaryPhone = meUserFull?.phones && meUserFull.phones.find((item) => item.phoneTypeId === 1)!.phone;

  const renderDeleteModal = () => {
    return (
      <Modal open={isDeleteModalVisible} footer={false} closable={false} width={615}>
        <p>
          Удаление профиля приведёт к удалению учетной записи и персональных данных. <br />
          Вы подтверждаете изменение?
        </p>
        <div style={{ textAlign: 'center' }}>
          <Space>
            <Button onClick={() => setIsDeleteModalVisible(false)}>Отмена</Button>
            <Button
              type="primary"
              onClick={() => {
                axios
                  .delete(profileRoute())
                  .then(() => {
                    LocalStorageService.clearToken();
                    window.location.reload();
                    message.success('Ваш профиль удалён', 3);
                  })
                  .catch(() => {
                    message.error('Произошла ошибка. Пожалуйста, попробуйте позднее', 3);
                  });
              }}
            >
              Подтвердить
            </Button>
          </Space>
        </div>
      </Modal>
    );
  };

  const repeatedPasswordValidator = (field, repeatedPassword) => {
    const password = passwordForm.getFieldValue('newPassword');
    if (repeatedPassword !== password) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const profileInfo = () => (
    <Col className="info-column">
      <Row justify="space-between" gutter={73}>
        <Col>
          <Row className="label_item">Статус пользователя:</Row>
          <Row>
            <span className="info_item">
              {personStatuses.find((status) => status.id === meUserFull?.personStatusId)?.name}
            </span>
          </Row>
        </Col>
        {hasAccess(['PERSON.READ.PROJECTS']) && (
          <Col>
            <Link to={`/files/${meUserFull?.id}/projects`} target="_blank">
              <Button
                type="primary"
                onClick={() => {
                  FireBaseService.createLogEventFireBase('Событие. Переход из профиля в личную анкету');
                }}
              >
                Моё участие
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <Row>
        <span className="fullName_item">{meUserFull?.lastName}</span>
      </Row>
      <Row>
        <span className="fullName_item">{meUserFull?.firstName}</span>
      </Row>
      <Row>
        <span className="fullName_item">{replaceFalse(meUserFull?.middleName)}</span>
      </Row>
      <Row justify="start" gutter={[40, 6]}>
        <Col span={24}>
          <Row className="label_item">Почта:</Row>
          <Row>
            <span className="info_item">{meUserFull?.email}</span>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="label_item">Телефон:</Row>
          <Row>
            <span className="info_item">{primaryPhone && getMaskedPhoneNumber(primaryPhone)}</span>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="label_item">Логин:</Row>
          <Row>
            <span className="info_item">{personProfileShortInfo?.login}</span>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="label_item">Тип пользователя:</Row>
          <Row>
            <span className="info_item">{personTypes.find((type) => type.id === meUserFull?.personTypeId)?.name}</span>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="label_item">Дата рождения:</Row>
          <Row>
            <span className="info_item">{toDisplayFormat(meUserFull?.birthDate)}</span>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[0, 15]}>
        <Button type="primary" className="display-flex" onClick={() => setIsEdit(true)}>
          <PencilIcon className="ml20" />
          <div className="ml6">Редактировать</div>
        </Button>
        <Button onClick={() => setIsDeleteModalVisible(true)}>Удалить профиль</Button>
      </Row>
    </Col>
  );

  const newPasswordValidator = (field, repeatedPassword) => {
    const newPassword = passwordForm.getFieldValue('newPassword');
    const oldPassword = passwordForm.getFieldValue('oldPassword');
    if (newPassword === oldPassword) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const profileEditInfo = () => (
    <Col className="info-column" style={{ width: '100%' }}>
      <Form
        form={infoForm}
        layout="vertical"
        initialValues={{
          birthDate: moment(meUserFull?.birthDate),
          lastName: meUserFull?.lastName,
          firstName: meUserFull?.firstName,
          middleName: meUserFull?.middleName,
          email: meUserFull?.email,
          phone: meUserFull?.phones && meUserFull.phones.find((item) => item.phoneTypeId === 1)?.phone,
          noMiddleName: !meUserFull?.middleName,
        }}
        onFinish={onFinish}
        onReset={() => {
          setIsEdit(false);
          infoForm.resetFields();
        }}
        className="mb36"
      >
        <Form.Item label="Статус пользователя:">
          <Row>
            <Col>
              <span style={{ marginLeft: 5 }} className="info_item">
                {personStatuses.find((status) => status.id === meUserFull?.personStatusId)?.name}
              </span>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Фамилия"
          rules={[
            {
              required: true,
              message: '',
            },
            {
              validator: fioValidator,
              message: 'Недопустимые символы',
            },
            {
              validator: fioLengthValidator,
              message: 'Максимум 64 символа',
            },
          ]}
        >
          <Input style={{ marginLeft: 4 }} />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="Имя"
          rules={[
            {
              required: true,
              message: '',
            },
            {
              validator: fioValidator,
              message: 'Недопустимые символы',
            },
            {
              validator: fioLengthValidator,
              message: 'Максимум 64 символа',
            },
          ]}
        >
          <Input style={{ marginLeft: 4 }} />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) => prevValues?.noMiddleName !== currentValues?.noMiddleName}
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item
                name="middleName"
                label="Отчество"
                rules={[
                  {
                    required: !getFieldValue('noMiddleName'),
                    message: '',
                  },
                  {
                    validator: !getFieldValue('noMiddleName') ? fioValidator : undefined,
                    message: 'Недопустимые символы',
                  },
                  {
                    validator: !getFieldValue('noMiddleName') ? fioLengthValidator : undefined,
                    message: 'Максимум 64 символа',
                  },
                ]}
              >
                <Input style={{ marginLeft: 4 }} disabled={getFieldValue('noMiddleName')} />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item valuePropName="checked" name="noMiddleName" style={{ display: 'flex', justifyContent: 'start' }}>
          <Checkbox style={{ marginLeft: 4 }}>Нет отчества</Checkbox>
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: 'email',
              message: '',
            },
          ]}
        >
          <Input style={{ marginLeft: 4 }} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Телефон"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <MaskedInput
            mask={[{ mask: phoneMask }]}
            name="phone"
            //TODO Eugene. Разобраться: без указания prefix=" " не срабатывает подсветка валидации
            prefix=" "
            placeholder={phoneInputPlaceholder}
            allowClear
            style={{ marginLeft: 4 }}
          />
        </Form.Item>
        <Form.Item label="Логин">
          <Row>
            <Col>
              <span style={{ marginLeft: 5 }} className="info_item">
                {personProfileShortInfo?.login}
              </span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Тип пользователя:">
          <Row>
            <Col>
              <span style={{ marginLeft: 5 }} className="info_item">
                {personTypes.find((type) => type.id === meUserFull?.personTypeId)?.name}
              </span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="birthDate"
          label="Дата рождения"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          style={{ display: 'flex', justifyContent: 'start' }}
        >
          <DatePicker
            style={{ marginLeft: 4, width: 150 }}
            format={displayFormat}
            disabledDate={(date) => date.isAfter(moment().add(1, 'day').subtract(18, 'years'))}
            inputRender={(props) => {
              return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
            }}
          />
        </Form.Item>

        <div style={{ position: 'absolute' }}>
          <Space>
            <Button htmlType="reset">Отмена</Button>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
            <Button onClick={() => setIsDeleteModalVisible(true)}>Удалить профиль</Button>
          </Space>
        </div>
      </Form>
    </Col>
  );

  const findSubscribtion = subscriptions.find((subscription) => subscription.notificationTypeId === 15);

  useEffect(() => {
    const initialValues = {
      projectIds: findSubscribtion?.subscriptionCondition?.projectIds,
      appSubjectIds: findSubscribtion?.subscriptionCondition?.appSubjectIds,
      wardCategoryIds: findSubscribtion?.subscriptionCondition?.wardCategoryIds,
    };
    subscriptionForm.setFieldsValue(initialValues);
  }, [subscriptions]);

  const subscriptionsInfo = () => {
    const allAppSubjectIds = appSubject.map((item) => item.id);
    const allWardCategoryIds = personCategories.map((item) => item.id);
    const allProjectsIds = projects
      .filter((project) => project.regionId === meUserFull?.regionId && project.projectTypeId === 1)
      .map((item) => item.id);

    const onFinishSubscriptions = (val) => {
      setIsUpdateSubscription(true);

      handleEditSubscriptions(val)
        .then(() => {
          setIsEditSubscription(false);
        })
        .finally(() => {
          setIsUpdateSubscription(false);
        });
    };

    const onCheckAll = (e: CheckboxChangeEvent, listType: 'projectIds' | 'appSubjectIds' | 'wardCategoryIds') => {
      switch (listType) {
        case 'projectIds':
          subscriptionForm.setFieldsValue({
            [listType]: e.target.checked ? allProjectsIds : [],
          });

          break;
        case 'appSubjectIds':
          subscriptionForm.setFieldsValue({
            [listType]: e.target.checked ? allAppSubjectIds : [],
          });

          break;
        case 'wardCategoryIds':
          subscriptionForm.setFieldsValue({
            [listType]: e.target.checked ? allWardCategoryIds : [],
          });

          break;
      }
    };

    const isCheckedAll = (listType: 'projectIds' | 'appSubjectIds' | 'wardCategoryIds', allCheckIds): boolean => {
      switch (listType) {
        case 'projectIds':
          return allProjectsIds.length === allCheckIds?.length;
        case 'appSubjectIds':
          return allAppSubjectIds.length === allCheckIds?.length;
        case 'wardCategoryIds':
          return allWardCategoryIds.length === allCheckIds?.length;
      }
    };

    return (
      <Form form={subscriptionForm} onFinish={onFinishSubscriptions} className="subscription-list-form">
        <Collapse
          defaultActiveKey={['1']}
          ghost
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <ArrowDownFigIcon className={`subscription-list-form-arrow ${isActive ? 'rotate' : ''}`} />
          )}
        >
          <Collapse.Panel header="Проект" key="1">
            {Array.isArray(projects) && projects.length > 0 && (
              <>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => prevValues?.projectIds !== currentValues?.projectIds}
                  noStyle
                >
                  {({ getFieldValue }) => {
                    return (
                      <div>
                        <Checkbox
                          value={uniqueId()}
                          onChange={(e) => onCheckAll(e, 'projectIds')}
                          disabled={!isEditSubscription}
                          className="font-size-12 mr3"
                          checked={isCheckedAll('projectIds', getFieldValue('projectIds'))}
                        />
                        <span style={{ fontWeight: 700 }} className="subscription-list-item">
                          Выбрать всё
                        </span>
                      </div>
                    );
                  }}
                </Form.Item>
                <Form.Item name="projectIds" style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                  <Checkbox.Group style={{ width: '100%' }}>
                    {projects
                      .filter((project) => project.regionId === meUserFull?.regionId && project.projectTypeId === 1)
                      .map((item) => (
                        <div key={item.id}>
                          <Checkbox value={item.id} disabled={!isEditSubscription} className="font-size-12 mr3" />
                          <span className="subscription-list-item">{item.title}</span>
                        </div>
                      ))}
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}
          </Collapse.Panel>
          <Collapse.Panel header="Вид помощи" key="2">
            {Array.isArray(appSubject) && appSubject.length > 0 && (
              <>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues?.appSubjectIds !== currentValues?.appSubjectIds
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    return (
                      <div>
                        <Checkbox
                          value={uniqueId()}
                          onChange={(e) => onCheckAll(e, 'appSubjectIds')}
                          disabled={!isEditSubscription}
                          className="font-size-12 mr3"
                          checked={isCheckedAll('appSubjectIds', getFieldValue('appSubjectIds'))}
                        />
                        <span style={{ fontWeight: 700 }} className="subscription-list-item">
                          Выбрать всё
                        </span>
                      </div>
                    );
                  }}
                </Form.Item>
                <Form.Item name="appSubjectIds" style={{ display: 'flex', justifyContent: 'start' }}>
                  <Checkbox.Group style={{ width: '100%' }}>
                    {appSubject.map((item) => (
                      <div key={item.id}>
                        <Checkbox value={item.id} disabled={!isEditSubscription} className="font-size-12" />
                        <span className="subscription-list-item">{item.name}</span>
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}
          </Collapse.Panel>
          <Collapse.Panel header="Категория" key="3">
            {Array.isArray(personCategories) && personCategories.length > 0 && (
              <>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues?.wardCategoryIds !== currentValues?.wardCategoryIds
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    return (
                      <div>
                        <Checkbox
                          value={uniqueId()}
                          onChange={(e) => onCheckAll(e, 'wardCategoryIds')}
                          disabled={!isEditSubscription}
                          className="font-size-12 mr3"
                          checked={isCheckedAll('wardCategoryIds', getFieldValue('wardCategoryIds'))}
                        />
                        <span style={{ fontWeight: 700 }} className="subscription-list-item">
                          Выбрать всё
                        </span>
                      </div>
                    );
                  }}
                </Form.Item>
                <Form.Item name="wardCategoryIds" style={{ display: 'flex', justifyContent: 'start' }}>
                  <Checkbox.Group style={{ width: '100%' }}>
                    {personCategories.map((item) => (
                      <div key={item.id}>
                        <Checkbox value={item.id} disabled={!isEditSubscription} className="font-size-12" />
                        <span className="subscription-list-item">{item.name}</span>
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}
          </Collapse.Panel>
        </Collapse>

        <div className="mt20 display-flex ml6" style={{ alignItems: 'start' }}>
          {isEditSubscription && (
            <>
              <Button
                onClick={() => {
                  setIsEditSubscription(false);
                  subscriptionForm.setFieldsValue({
                    projectIds: findSubscribtion?.subscriptionCondition?.projectIds,
                    appSubjectIds: findSubscribtion?.subscriptionCondition?.appSubjectIds,
                    wardCategoryIds: findSubscribtion?.subscriptionCondition?.wardCategoryIds,
                  });
                }}
                style={{ width: 150 }}
              >
                Отмена
              </Button>
              <Button className="ml30" type="primary" htmlType="submit" style={{ width: 150 }}>
                Сохранить
              </Button>
            </>
          )}
          {!isEditSubscription && (
            <Button
              type="primary"
              className="display-flex"
              onClick={() => setIsEditSubscription(true)}
              disabled={checkIsLoading(subscriptionsLoading)}
            >
              <PencilIcon className="ml20" />
              <div className="ml6">Настроить</div>
            </Button>
          )}
        </div>
      </Form>
    );
  };

  return (
    <div className="user-profile">
      {renderDeleteModal()}
      <h2 className="ml20">Профиль пользователя</h2>
      <div className="container pl20 pr20 pt20 pb20">
        <Row justify="start" gutter={20} style={{ flexWrap: 'wrap' }}>
          <Col span={12}>
            <h3 className="title">Личные и контактные данные</h3>
            <Divider />

            <Row justify="start" gutter={20} style={{ flexWrap: 'nowrap' }}>
              <Col>
                <ProfileImage myProfile={true} image={image} handleNewImage={handleNewImage} />
              </Col>
              {isEdit ? profileEditInfo() : profileInfo()}
            </Row>
          </Col>
          <Col span={6}>
            <h3 className="title">Смена пароля</h3>
            <Divider />
            <PasswordHint shortVersion />
            <Form
              form={passwordForm}
              name="basic"
              initialValues={{ remember: true }}
              layout="vertical"
              onFinish={(values) => {
                handleChangePassword({
                  oldPassword: values.oldPassword,
                  newPassword: values.newPassword,
                });
              }}
              onValuesChange={(field, fields) => {
                if (Object.values(fields).every((item) => item === undefined || item === '')) {
                  setIsPassButtonDisabled(true);
                } else setIsPassButtonDisabled(false);
              }}
            >
              <Form.Item
                label="Текущий пароль"
                name="oldPassword"
                dependencies={['newPassword']}
                rules={[
                  { required: true, message: '' },
                  {
                    validator: newPasswordValidator,
                    message: 'Новый пароль совпадает с текущим',
                  },
                ]}
                className="mt10"
              >
                <Input.Password placeholder="Введите пароль" allowClear />
              </Form.Item>
              <Form.Item
                label="Новый пароль"
                name="newPassword"
                rules={[
                  { required: true, message: '' },
                  {
                    pattern: passwordPattern,
                    message: 'Пароль ненадежный, введите другой пароль',
                  },
                ]}
                className="mt10"
              >
                <Input.Password placeholder="Введите пароль" allowClear />
              </Form.Item>
              <Form.Item
                label="Подтвердите пароль"
                name="repeatedPassword"
                dependencies={['newPassword']}
                rules={[
                  { required: true, message: '' },
                  {
                    validator: repeatedPasswordValidator,
                    message: 'Пароли не совпадают',
                  },
                ]}
                className="mt10"
              >
                <Input.Password placeholder="Повторите введенный пароль" allowClear />
              </Form.Item>
              <div className="mt20 display-flex">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="password-recovery-submitBtn"
                  disabled={isPassButtonDisabled}
                >
                  Сохранить
                </Button>
              </div>
            </Form>
          </Col>
          {hasAccess(['PROFILE.SUBSCRIPTION']) && (
            <Col span={6}>
              <h3 className="title">Подписка на просьбы</h3>
              <Divider />

              <div className="font-roboto text-left ml5">
                Выберите интересующие характеристики, и мы будем оповещать Вас о подходящих просьбах
              </div>

              <Row justify="start" gutter={20} style={{ flexWrap: 'nowrap' }}>
                <Col style={{ width: '100%' }}>
                  <Spin
                    spinning={
                      checkIsLoading(
                        projectsLoading,
                        subscriptionsLoading,
                        appSubjectLoading,
                        personCategoriesLoading
                      ) || isUpdateSubscription
                    }
                  >
                    {subscriptionsInfo()}
                  </Spin>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

export default UserProfile;
