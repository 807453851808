import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAllNotifications, getUnreadNotifications } from '../../store/slices/notificationsSlice/notificationsSlice';
import Notifications from '../../components/Notifications/Notifications';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ISearchType } from './NotificationsContainerTypes';

function NotificationsContainer(props) {
  const dispatch = useAppDispatch();
  const { meUserFull } = useAppSelector((state) => state.persons);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState<ISearchType>();

  const fetchNotifications = () => {
    if (meUserFull?.userId && searchType) {
      dispatch(getAllNotifications({ page: page - 1, size: 20, userId: meUserFull.userId, searchType }));
      dispatch(getUnreadNotifications({ page: 0, size: 100, userId: meUserFull.userId, searchUnread: true }));
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [meUserFull, page, dispatch, searchType]);

  useEffect(() => {
    const searchTypeFormat = location.pathname?.split('/');

    switch (searchTypeFormat[searchTypeFormat.length - 1]) {
      case 'all':
        setSearchType('ALL');
        break;
      case 'news':
        setSearchType('NEWS');
        break;
      case 'work_with_app':
        setSearchType('WORK_WITH_APP');
        break;
      case 'suitable_app':
        setSearchType('SUITABLE_APP');
        break;
      default:
        setSearchType(undefined);
        break;
    }
  }, [location.pathname]);

  const routes = ['suitable_app', 'work_with_app', 'news', 'all'];

  return (
    <div className="container">
      <Routes>
        {/*Если ничего нет в строке адресной переадресовывать на all*/}
        <Route path="/*" element={<Navigate to={'/notifications/all'} />} />

        {/*Если что-то в строке адресной лишнее переадресовывать на all*/}
        {routes.map((el) => (
          <Route path={`${el}/*`} element={<Navigate to={'/notifications/all'} />} />
        ))}

        {/*Существующие маршруты*/}
        {routes.map((el) => (
          <Route
            path={el}
            element={<Notifications page={page} setPage={setPage} fetchNotifications={fetchNotifications} />}
          />
        ))}
      </Routes>
    </div>
  );
}

export default NotificationsContainer;
