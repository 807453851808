import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchPersonBody } from '../../../lib/interfaces/SearchPersonBody';
import { Application } from '../../../lib/interfaces/Application';
import axios from 'axios';
import { publicConfigurationRoute } from '../../../lib/routes/routes';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';

const filesSearchStateFilesSearchStateInitial = {
  fio: undefined,
  personTypeIds: [],
  excludePersonTypeId: [],
  personStatusIds: [],
  email: undefined,
  phone: undefined,
  maritalStatusId: undefined,
  birthDate: undefined,
  deathDate: undefined,
  regionId: undefined,
  projectIds: [],
  address: undefined,
  subwayStationId: undefined,
  excludePersonInPrayList: undefined,
};

interface InitialState {
  properties: any;
  propertiesLoading: RequestStatuses;
  filesSearchState: SearchPersonBody;
  filesPage: number;
  filesInPrayListFilter: boolean;
  selectedTypesId: number | undefined;
}

const initialState: InitialState = {
  properties: {},
  propertiesLoading: requestStatusCodes.idle,
  filesSearchState: filesSearchStateFilesSearchStateInitial,
  filesPage: 1,
  filesInPrayListFilter: false,
  selectedTypesId: undefined,
};

export const getProperties = createAsyncThunk(
  'root/getProperties',
  async ({ tenantId }: { tenantId: number }): Promise<Application> => {
    const response = await axios.get(publicConfigurationRoute(tenantId));
    // console.log(response.data)
    const { data } = response;
    return data;
  }
);

const rootSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setFilesSearchState: (state, action) => {
      state.filesSearchState = action.payload;
    },
    resetFilesSearchState: (state) => {
      state.filesSearchState = filesSearchStateFilesSearchStateInitial;
    },
    setFilesPage: (state, action: PayloadAction<number>) => {
      state.filesPage = action.payload;
    },
    setFilesInPrayListFilter: (state, action: PayloadAction<boolean>) => {
      state.filesInPrayListFilter = action.payload;
    },
    setSelectedTypesIdFilter: (state, action: PayloadAction<number | undefined>) => {
      state.selectedTypesId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProperties.pending, (state) => {
        state.propertiesLoading = requestStatusCodes.pending;
      })
      .addCase(getProperties.fulfilled, (state, action) => {
        state.propertiesLoading = requestStatusCodes.fulfilled;
        state.properties = action.payload;
      })
      .addCase(getProperties.rejected, (state) => {
        state.propertiesLoading = requestStatusCodes.rejected;
      });
  },
});

const { reducer, actions } = rootSlice;
const { setFilesSearchState, resetFilesSearchState, setFilesPage, setFilesInPrayListFilter, setSelectedTypesIdFilter } =
  actions;

export {
  reducer,
  setFilesSearchState,
  resetFilesSearchState,
  setFilesPage,
  setFilesInPrayListFilter,
  setSelectedTypesIdFilter,
};
