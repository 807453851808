import React, { useEffect } from 'react';
import FileTabs from '../../components/FileTabs/FileTabs';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import {
  getMaritalStatusDictionary,
  getRegionDictionary,
  getSubwayStationDictionary,
} from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { cleanPersonFullCard, getPersonFullCard } from '../../store/slices/personsSlice/personsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Spin, Row } from 'antd';
import { getProjects } from '../../store/slices/projectsSlice/projectsSlice';
import { ReactComponent as BackArrow } from '../../lib/img/back-arrow.svg';

function FileMainContainer() {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { maritalStatusLoading, regionLoading, subwayStationLoading } = useAppSelector((state) => state.dictionaries);
  const { personFullCard, personFullCardLoading } = useAppSelector((state) => state.persons);
  const { projectsLoading } = useAppSelector((state) => state.projects);

  useEffect(() => {
    return () => {
      dispatch(cleanPersonFullCard());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!checkIsLoaded(maritalStatusLoading)) dispatch(getMaritalStatusDictionary());
    if (!checkIsLoaded(regionLoading)) dispatch(getRegionDictionary());
    if (!checkIsLoaded(subwayStationLoading)) dispatch(getSubwayStationDictionary());
    if (!checkIsLoaded(projectsLoading)) dispatch(getProjects({}));
  }, [dispatch]);

  useEffect(() => {
    if (fileId) {
      dispatch(getPersonFullCard({ id: Number(fileId) }));
    }
  }, [dispatch]);

  const shouldRender = () => checkIsLoaded(maritalStatusLoading, regionLoading, subwayStationLoading, projectsLoading);

  return (
    <Spin
      className="container_height"
      spinning={checkIsLoading(
        personFullCardLoading,
        projectsLoading,
        maritalStatusLoading,
        regionLoading,
        subwayStationLoading
      )}
    >
      {shouldRender() && (
        <>
          <div className="pl20 pr20">
            <Row>
              <BackArrow className="ml6 mt8 mr10 cursor-pointer" onClick={() => navigate('..')} />
              <h2
                className="fullName"
                style={{
                  textDecoration: 'underline',
                  textDecorationColor: '#7590A3',
                  textUnderlineOffset: '7px',
                }}
              >{`${personFullCard?.lastName} ${personFullCard?.firstName} ${
                personFullCard?.middleName ? personFullCard?.middleName : ''
              }`}</h2>
            </Row>
            <FileTabs />
          </div>
          <Outlet />
        </>
      )}
    </Spin>
  );
}

export default FileMainContainer;
